import React from "react"
import { graphql } from "gatsby"
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import GridList from "@material-ui/core/GridList"
import Paper from "@material-ui/core/Paper"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ProductCard from "../components/product-card"
import { Typography } from "@material-ui/core"

const useStyles = makeStyles(({ palette, spacing }: Theme) =>
  createStyles({
    productGroup: {
      background: palette.grey.A100,
      marginTop: spacing(3),
      padding: spacing(1),
    },
  })
)

const groupBy = (list, keyGetter) => {
  const map = new Map()
  list.forEach(item => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}

const IndexPage = ({
  data: {
    allMarkdownRemark: { nodes: products },
  },
}) => {
  const classes = useStyles()
  const productGroups = groupBy(
    products,
    product => product.frontmatter.productType
  )
  const ProductGroup = ({ title, products }) => (
    <Container>
      <Paper variant="outlined" square className={classes.productGroup}>
        <Typography variant="h3">{title}</Typography>
        <GridList>
          {products.map(product => (
            <ProductCard key={product.id} product={product} />
          ))}
        </GridList>
      </Paper>
    </Container>
  )

  return (
    <Layout>
      <SEO title="Home" />
      <Container>
        {Array.from(productGroups).map(productGroup => (
          <ProductGroup
            key={productGroup[0]}
            title={productGroup[0]}
            products={productGroup[1]}
          />
        ))}
      </Container>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query indexPageQuery {
    allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "Product" } } }
      sort: { fields: frontmatter___productType, order: ASC }
    ) {
      nodes {
        id
        excerpt(pruneLength: 310)
        fields {
          slug
        }
        frontmatter {
          price
          productType
          shortDescription
          sku
          tags
          thumbnail {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
              resize(width: 80) {
                src
              }
            }
          }
          title
          variant1Name
          variant1Options
        }
        html
        rawMarkdownBody
      }
    }
  }
`
